<template>
<div>
          <img class="signIn-logo" :src="logoSignIn" v-if="logoSignIn && !loading" />
          <img
              :alt="title"
              class="signIn-logo"
              src="/the-one-stellenbosch/images/logo.svg"
              v-if="!logoSignIn && !loading"
          />
</div>
</template>

<script>
import { settingsCollection } from "../../firebase.js";
import {APP_TITLE} from "@/Constants";

export default {
  name: "PageLogo",
  data() {
    return {
      title: APP_TITLE,
      logoSignIn: null,
      loading: true,
    };
  },
  created() {
    settingsCollection.doc("globalSettings").onSnapshot((doc) => {
      this.logoSignIn = doc.data().logoSignIn;
      this.loading = false;
    });
  },
};
</script>

<style>
.signIn-logo {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
}
</style>
