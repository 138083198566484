<template>
  <div
    class="min-w-screen min-h-screen bg-secondary flex items-center justify-center px-5 py-5"
  >
    <div
      class="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
      style="max-width: 1000px"
    >
      <div class="md:flex w-full">
        <div
            class="hidden imageDivClass md:flex bg-cover"
        >
          <img class="hidden md:flex bg-cover" :src="backgroundSignIn" v-if="backgroundSignIn && !loading" />
          <img
              :alt="title"
              class="hidden md:flex bg-cover"
              src="/the-one-stellenbosch/images/login.jpg"
              v-if="!backgroundSignIn && !loading"
          />

                    <PageLogo />

        </div>
        <div class="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div class="text-center mb-10">
            <h1 class="font-bold text-3xl text-gray-900">Reset Password</h1>
            <p>Enter your email address to reset your password</p>
          </div>

          <form class="relative" @submit.prevent="submit" @keyup.enter.prevent="submit">

            <div v-if="showSuccessMessage" class="absolute top-0 left-0 z-20 h-full w-full bg-gray-100">
              <div class="flex flex-col items-center justify-center h-full w-full">
                <PasswordResetIcon :size=50 class="text-primary" />
                <p class="mt-5 text-md font-semibold">Password Reset</p>
                <p class="mt-5 text-sm font-semibold">Please view your email to complete the process</p>

                <router-link
                  class="block mt-5 text-center text-sm transform hover:scale-110"
                  :to="{name: 'login'}"
                >
                  Go back to Login.
                </router-link>
              </div>
            </div>

            <div class="flex -mx-3">
              <div class="w-full px-3 mb-2">
                <label for="login-email" class="text-xs font-semibold px-1">Email</label>
                <div class="flex">
                  <div
                    class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
                  >
                    <EmailIcon />
                  </div>
                  <input
                    v-model="form.email"
                    id="login-email"
                    type="text"
                    name="email"
                    class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary-ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                    placeholder=""
                  />
                </div>
                <span
                  class="text-xs text-red-600"
                  :class="{ '': $v.form.email.$error, 'invisible': !$v.form.email.$error }">
                  Please enter a valid email address</span>
              </div>
            </div>

            <div class="flex -mx-3 mt-5">
              <div class="w-full px-3 mb-5">
                <button
                  class="block w-full max-w-xs mx-auto bg-primary hover:bg-primary focus:bg-primary text-white rounded-lg px-3 py-3 font-semibold"
                  type="submit"
                >
                  RESET PASSWORD
                </button>

                <router-link
                  class="block mt-5 text-center text-sm transform hover:scale-110"
                  :to="{name: 'register'}">Need an account? Register.</router-link>


              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import {required, email} from "vuelidate/lib/validators"
import PasswordResetIcon from 'vue-material-design-icons/LockReset';
import EmailIcon from 'vue-material-design-icons/At';
import PageLogo from '@/components/app/PageLogo.vue';

import {APP_TITLE} from '../../Constants.js'
import {settingsCollection} from "@/firebase";

export default {
  name: 'Login',
  components: {
    EmailIcon,
    PasswordResetIcon,
    PageLogo
  },
  data: () => ({
    showSuccessMessage: false,
    form: {
      email: "",
    },
    title: APP_TITLE,
    backgroundSignIn: null,
    logoSignIn: null,
    loading: true,
  }),
  validations: {
    form: {
      email: {required, email},
    }
  },
  methods: {
    submit() {
      this.$v.form.$touch()
      if(this.$v.form.$error) return

      firebase.auth()
        .sendPasswordResetEmail(this.form.email)
        .then(() => {
        this.showSuccessMessage = true
      })
        .catch(error => {
          this.showSuccessMessage = false
          this.$notify({
            group: 'global',
            title: error.message,
            type: 'error'
          })
        })
    },
  },
  created() {
    settingsCollection.doc("globalSettings").onSnapshot((doc) => {
      this.backgroundSignIn = doc.data().backgroundSignIn;
      this.logoSignIn = doc.data().logoSignIn;
      this.loading = false;
    });
  },
}
</script>

<style>
.signIn-logo {
     position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
}
.imageDivClass {
  position: relative;
}
</style>
